import { FullSizeSection, ContentSection } from '../Section/index';
import facebook from './facebook-white.png';
import linkedin from './linkedin.png';

function Footer(props) {
  const sectionStyle = {
    position: 'absolute',
    bottom: 0,
    minHeight: '290px',
    marginBottom: '0px',
    backgroundColor: 'black',
  };
  const textStyle = {
    textAlign: 'center',
    color: 'white',
  };

  const socialmediaStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '15px',
  };

  return (
    <FullSizeSection key={'image-w-header' + props.id} style={sectionStyle}>
      <ContentSection>
        <div style={textStyle}>
          <h2>Kontakta oss!</h2>

          <div>
            <div>Marcus Söderberg </div>
            <a
              href='mailto: info@nevermindmedia.se'
              style={{ textDecoration: 'none' }}
            >
              info@nevermindmedia.se
            </a>

            <br />
          </div>
          <a href='tel:+46709907861' style={{ textDecoration: 'none' }}>
            070- 990 78 61
          </a>

          <p>Stora Nygatan 21, 111 27 Stockholm</p>
        </div>

        <div style={socialmediaStyle}>
          <a href='https://www.facebook.com/nevermindmediaab/'>
            <img src={facebook} width='30px' style={{ margin: '4px' }} />
          </a>
          <a href='https://www.linkedin.com/company/nevermind-ab/?originalSubdomain=se'>
            <img src={linkedin} width='30px' style={{ margin: '4px' }} />
          </a>
        </div>
      </ContentSection>
    </FullSizeSection>
  );
}

export default Footer;
