import { FullSizeSection, ContentSection } from '../../Section';
import { ParseBgColor, ParseTextColor } from '../../../utils/parsers.js';
import Card from './Card';

export default function ContactView(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
    margin: '60px 0',
  };

  const contentStyle = {
    backgroundColor: ParseBgColor(props.bgColor),
    color: ParseTextColor(props.textColor),
  };

  const gridStyle = {
    display: 'flex',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  };

  const listItems = props.minds
    .sort((a, b) => a.order - b.order)
    .map((mind, i) => <Card key={'mind' + i.toString()} value={mind} />);

  return (
    <FullSizeSection key={'crowd-card' + props.id} style={fullSizeSectionStyle}>
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <h2 style={{ textAlign: 'center' }}>Kontakta någon av våra minds</h2>
        <div style={gridStyle}> {listItems}</div>
      </ContentSection>
    </FullSizeSection>
  );
}
