export const DEFAULT_HOME_LOCATION = 'Home' ;
export const IMAGE_FORMATS = {
    thumbnail : "Thumbnail",
    small : "Small",
    medium : "Medium",
    large : "Large",
    banner : "Banner"
};
export const IMAGE_TYPES = {
    background : "background"
};
