import {FullSizeSection, ContentSection} from "../../Section";
import {ParseBgColor, ParseTextColor} from "../../../utils/parsers.js";
import Card from "./Card";
import Markdown from "../../Markdown";

export default function ClientView(props) {

    const fullSizeSectionStyle = {
        backgroundColor: ParseBgColor(props.outerBgColor)
    };

    const contentStyle = {
        backgroundColor: ParseBgColor(props.bgColor),
        color:  ParseTextColor(props.textColor)
    };

    let listItems = null;
    if(Array.isArray(props.clients) && props.clients.length) {
        listItems = props.clients.map((client, i) =>
            <Card key={'client' + i.toString()} value={client} />
        );
    }

    return(
        <FullSizeSection key={'crowd-card' + props.id} style={fullSizeSectionStyle}>
            <ContentSection style={contentStyle} anchor={props.anchor}>
                <div className='col-12'>
                    {Markdown(props.content)}
                </div>
                <div className='col-12'>
                    {listItems}
                </div>
            </ContentSection>
        </FullSizeSection>
    );
};
