import { ParseBgColor } from '../../../utils/parsers.js';
import Markdown from '../../Markdown';

export default function Card({ value, backgroundColor, color, tag }) {
  if (typeof value === 'undefined') return null;

  const cardStyle = {
    backgroundColor: backgroundColor,
    color: ParseBgColor(color),
  };

  console.log(tag);
  return (
    <div
      style={cardStyle}
      className={tag === 'job0' ? 'job-card-first' : 'job-card'}
    >
      <h4>{value.label}</h4>
      <div className='card-text'>{Markdown(value.desc)}</div>
    </div>
  );
}
