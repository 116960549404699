import {IMAGE_FORMATS} from "../../../utils/Constants.js";
import {ParseBgImage} from "../../../utils/parsers.js";

export default function Card({value}) {

    if (typeof(value) === "undefined") return null;

    return(
        <div className='col-3 center' style={{overflow:'hidden', paddingBottom:"30px"}}>
            <img width="40%" src={ParseBgImage({image:value.logo, format:IMAGE_FORMATS.thumbnail})} alt='Client'/>
            <br/>
            <b>
                {value.name}
            </b>
        </div>
    );
};
