import {FullSizeSection, ContentSection} from "../../Section";
import {ParseBgColor, ParseTextColor} from "../../../utils/parsers.js";
import Card from "./Card";
import Markdown from "../../Markdown";

export default function MindsView(props) {

    const fullSizeSectionStyle = {
        backgroundColor: ParseBgColor(props.outerBgColor)
    };

    const contentStyle = {
        backgroundColor: ParseBgColor(props.bgColor),
        color:  ParseTextColor(props.textColor)
    };

    const listItems = props.minds
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map((mind, i) =>
        <Card key={'mind' + i.toString()} value={mind} />
    );

    return(
        <FullSizeSection key={'crowd-card' + props.id} style={fullSizeSectionStyle}>
            <ContentSection style={contentStyle} anchor={props.anchor}>
                <div>
                    {Markdown(props.content)}
                </div>
                <div className='mind-grid'>
                    {listItems}
                    
                </div>
            </ContentSection>
        </FullSizeSection>
    );
};
