export default function CoverElement({coverOpacity}) {

    if (typeof coverOpacity === undefined || !coverOpacity) return null;

    let opacity = parseInt(coverOpacity)/100;
    if( opacity <= 1 && opacity > 0){
        return (
            <div className='coverElement' style={{opacity:opacity}}></div>
        )
    }

    return null;
}
