import { IMAGE_FORMATS, IMAGE_TYPES } from './Constants';
import { motion } from 'framer-motion';
import Banner from '../components/blocks/Banner';
import CrowdView from '../components/blocks/CrowdView';
import ClientView from '../components/blocks/ClientView';
import JobView from '../components/blocks/JobView';
import GoogleMap from '../components/blocks/GoogleMap';
import JobForm from "../components/blocks/JobForm";
import MindsView from '../components/blocks/MindsView';
import Link from '../components/Link';
import TextWithImage from '../components/blocks/TextWithImage';
import VideoHero from '../components/blocks/VideoHero';
import ContactView from '../components/blocks/ContactView';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';

/*
    Function:
        BlockParser
    Desc:
        Returns a block component.
        A block object refers to the page content defined in strapi. A strapi webpage should countain an array of blocks. Each block represent a row on the final page. If component for the block is defined, BlockParser returns the corresponding component
    Input:
        block object [json]
    Outout:
        component or null
*/
const BlockParser = function (contentBlock) {
  if (typeof contentBlock === undefined || !contentBlock) {
    console.log('Page data is missing - 1');
    return null;
  }
  const blockData = contentBlock.block;

  if (typeof contentBlock === undefined || !contentBlock) {
    console.log('Page data is missing - 2');
    return null;
  }

  const contentType = blockData.__component;

  if (contentType === 'block.banner') {
    return Banner(blockData);
  } else if (contentType === 'block.clients') {
    return ClientView(blockData);
  } else if (contentType === 'block.crowds') {
    return CrowdView(blockData);
  } else if (contentType === 'block.google-map') {
    return GoogleMap(blockData);
  } else if (contentType === 'block.jobs') {
    return JobView(blockData);
  } else if (contentType === 'block.minds') {
    return MindsView(blockData);
  } else if (contentType === "block.job-form") {
    return JobForm(blockData);
  } else if (contentType === 'block.text-with-image') {
    return TextWithImage(blockData);
  } else if (contentType === 'block.video-hero') {
    return VideoHero(blockData);
  } else if (contentType === 'block.contact') {
    return ContactView(blockData);
  }
  return null;
};

// const pageVariants = {
//   initial: {
//     opacity: 0,
//     x: '-100vh',
//     scale: 0.8,
//   },
//   in: {
//     opacity: 1,
//     x: 0,
//     scale: 1,
//   },
//   out: {
//     opacity: 0,
//     x: '100vh',
//     scale: 0.8,
//   },
// };

// const pageTransition = {
//   type: 'Tween',
//   transition: 'anticipate',
//   duration: 0.5,
// };

const pageStyle = {
  position: 'relative',
  width: '100%',
};

const AnimationSettings = {
  transition: { duration: 0.5 },
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
};

/*
    Function:
        PageParser
    Desc:
        Returns a page component.
    Input:
        page object [json]
*/
export const PageParser = function (page) {
  if (typeof page === undefined || !page) return null;
  if (typeof page.content === undefined || !page.content) return null;

  const blocks = page.content.map((block) => {
    return BlockParser({ block });
  });

  return (
    <motion.div style={pageStyle} {...AnimationSettings}>
      {blocks}
    </motion.div>
  );
};

/*
    Function:
        PargeBgImage
    Desc:
        PargeBgImage should determine the most appropiate URL to the image.
*/
export const ParseBgImage = function (props) {
  if (props === undefined || props.image === undefined) return null;
  if (!props.image || !props.image.url) return null;

  let url;

  if (props.image.formats !== undefined && props.image.formats) {
    if (props.format === IMAGE_FORMATS.banner) {
      url = props.image.url;
    } else if (props.format === IMAGE_FORMATS.large) {
      url = props.image.formats.large.url;
    } else if (props.format === IMAGE_FORMATS.medium) {
      url = props.image.formats.medium.url;
    } else if (props.format === IMAGE_FORMATS.small) {
      url = props.image.formats.small.url;
    } else if (props.format === IMAGE_FORMATS.thumbnail) {
      url = props.image.formats.thumbnail.url;
    }
  }

  // This may load an unnecessary large image
  if (url === undefined || !url) url = props.image.url;

  if (url[0] === '/') {
    url = process.env.REACT_APP_STRAPI_URL + url;
  }

  if (props.type === IMAGE_TYPES.background) {
    return `url(${url})`;
  }

  return url;
};

export const ParserBgVideo = (props) => {
  if (props === undefined) return null;
  if (!props) return null;

  const url = props;

  return url;
};

/*
    Function:
        ParseBgColor
    Desc:
        ParseBgColor extracts html color from a strapi color objecy
    Input:
        color object [json]
    Outout:
        html color
*/
export const ParseBgColor = function (color) {
  if (!(color && color.code)) return 'none';
  return color.code;
};

/*
    Function:
        ParseLinks
    Input:
        Arrys of strapi link object [json]
    Outout:
        List of Link objects
*/
export const ParseLinks = function (links) {
  if (typeof links === undefined || !links) return null;
  if (typeof Array.isArray === 'undefined') return null;
  if (links.length === 0) return null;

  return links.map((link, i) => {
    link.key = 'link ' + i;
    link.rowBreak = true;
    return Link(link);
  });
};

/*
    Function:
        ParseTextColor
    Desc:
        ParseBgColor extracts html color from a strapi color objecy
    Input:
        color object [json]
    Outout:
        html color
*/
export const ParseTextColor = function (color) {
  if (!(color && color.code)) return '#000';
  return color.code;
};

/*
    Function:
        SocialmediaLinksParser
    Desc:
        Returns Facebook and LinkedIn icons as links
    Input:
        color of socialmedia icons
    Outout:
        social media icons
*/

export const SocialMediaLinksParser = (socialMediaLinks) => {
  if (socialMediaLinks === null || undefined) return null;
  if (!socialMediaLinks) return null;

  const socialMediaIconsStyleDark = () => {
    return { color: 'white' };
  };

  const socialMediaIconsStyleColor = (color) => {
    return { color: color };
  };

  return (
    <div style={{ margin: '10px 0 10px 0' }}>
      <a href='https://www.facebook.com/nevermindmediaab'>
        <FaFacebookSquare
          size={35}
          style={
            socialMediaLinks === 'white'
              ? socialMediaIconsStyleDark()
              : socialMediaIconsStyleColor('#3b5998')
          }
        />
      </a>
      <a href='https://www.linkedin.com/company/nevermind-ab/'>
        <FaLinkedin
          size={35}
          style={
            socialMediaLinks === 'white'
              ? socialMediaIconsStyleDark()
              : socialMediaIconsStyleColor('#0077b5')
          }
        />
      </a>
    </div>
  );
};
