import Markdown from "../../Markdown";
import Input from "../../Input"
import React, { useState } from "react"
import { FaPaperclip } from 'react-icons/fa';

export default function Form(props) {
    const [fileName, fileNameSet] = useState("")
    const [fileStatus, fileStatusSet] = useState(false)
    const [hasSubmitted, setSubmitDone] = useState(false)
    const [hasUserConsented, setConsent] = useState(false)
    const [wasFormPostError, setError] = useState(false)

    function doesInputHasValue(e) {
       var fileNameEsc = e.target.value.replace(/^.*[\\\/]/, '')
         fileNameSet(fileNameEsc)
         fileStatusSet(true)
   }

   function checkConsent(e) {
       var parent = e.target.parentNode
       var span = parent.querySelector('.required-field-text')
       setConsent(e.target.checked)
       if(e.target.checked) {
            span.style.color = ''
        } else {
            span.style.color = 'red'
        }
   }

   function validateEmail(e) {
    const emailValue = e.target.form.querySelector('input#email').value
    const regeEmail = new RegExp('@.*?\.');
    const isEmailValid = regeEmail.test(emailValue)
    if(!isEmailValid) {
        e.target.form.querySelector('input#email + span').style.color = 'red';
    }
    return isEmailValid
   }

   function sendMail(event) {  
        event.preventDefault();
        validateEmail(event)
        let formData = new FormData()
        console.log("send mail")

        let form = event.target.form
        let formParts = form.querySelectorAll('.form-label')

        formParts.forEach(element => {
            let input = element.querySelector('input')
            let requiredText = element.querySelector('.required-field-text')
            if(!input.validity.valid) {
                    if(requiredText !== null) {
                        requiredText.style.color = 'red'
                    }
            } else {
                if(requiredText !== null) {
                    requiredText.style.color = 'black'
                }
            }
            
        });

        if(hasUserConsented && validateEmail(event)) {
            event.target.form.contact_number.value = Math.random() * 100000 | 0
            formData.append("fname", event.target.form.fname.value)
            formData.append("lname", event.target.form.lname.value)
            formData.append("phone", event.target.form.phone.value)
            formData.append("email", event.target.form.email.value)
            formData.append("contact_number", event.target.form.contact_number.value)
            formData.append("message", event.target.form.message.value)
            formData.append("link", event.target.form.link.value)
            formData.append("cv", event.target.form.cv.files[0], event.target.form.cv.files[0].name)
            formData.append("consented", event.target.form.consented.value)

            fetch("https://api.nvrmind.se/jobapplication", {
                method: "POST",
                body: formData
                })
                .then(res => {
                    if (res.status == 200) {
                       setSubmitDone(true)
                    } else {
                        setError(true)
                    }
                  })
                  .catch(err => (console.log(err)));
                }
            }

return(
    <div>
        <div className={hasSubmitted ? 'form-intro hide' : 'form-intro'}>
            {props.introText !== null ? Markdown(props.introText) : <div><h2>Låter det intressant?</h2> <p>Hör av dig till oss och berätta mer om dig!</p></div>}
        </div>
        <div className='apply-form col-8'>
            <form id="contact-form" className={hasSubmitted ? 'hide' : ''} autoComplete="off" name="jobform" method="POST" encType="multipart/form-data">
                <Input type="hidden" for="contact_number" text=""></Input>

                <div class="wrapper-inputs">
                    <Input type="text" for="fname" text="Förnamn"></Input>

                    <Input type="text" for="lname" text="Efternamn"></Input>

                    <Input type="tel" for="phone" text="Telefon"></Input>

                    <Input type="email" for="email" text="E-post"></Input>
                </div>

                <input value={window.location.href} type="hidden" htmlFor="link" name="link"></input>

                <label className="message-label" htmlFor="message">Skriv lite om dig och varför du söker detta jobb:</label>
                <textarea name="message" required placeholder="Vem är du?"></textarea>

                <label htmlFor="cv">
                <div className="form-label">
                        <div className="file-selected" style={ fileStatus ? { display:'block'} : {display : 'none'} }>Vald fil: {fileName}</div>
                        <div className="file-upload">
                            <span>Ladda upp CV</span>
                            <span><FaPaperclip /></span>
                            <input type ="file" id="cv" name="cv" hidden required onChange={doesInputHasValue} accept=".pdf,.doc"></input>
                            <span className="required-field-text">*Obligatoriskt fält</span>
                        </div>
                </div>
                </label>
                    <div className="checkbox-wrapper form-label">
                        <input onClick={checkConsent} id="consented" name="consented" type="checkbox" required></input><span>Jag godkänner att Nevermind lagrar mina personuppgifter</span>
                        <span className="required-field-text">*Obligatoriskt fält</span>
                    </div>
                <div className="submit-wrapper">
                    <button type="submit" onClick={sendMail}>Skicka</button>
                </div>
            </form>
            <div className={hasSubmitted ? 'thank_you' : 'thank_you hide'}>
            {props.thankYouText !== null ? Markdown(props.thankYouText) : <div><h2>Tack för din ansökan!</h2> <p>Vi kommer titta på den och höra av oss.</p></div>}
            
            </div>
            <div className={wasFormPostError ? 'form_error' : 'form_error hide'}>
                {props.errorText !== null ? Markdown(props.errorText) : <div><h2>Något gick fel när vi försökte skicka din ansökan.</h2><p> Var vänlig försök igen om en stund. Om du upplever många problem så kan du ansöka direkt till work@nvrmind.se</p></div>}
            </div>
        </div>
    </div>
)
}
