import GoogleMapReact from 'google-map-react';
import { FullSizeSection, ContentSection } from '../../Section';
import { ParseBgColor, ParseTextColor } from '../../../utils/parsers.js';
import Markdown from '../../Markdown';
import './map.css';
import Card from '../ContactView/Card';

const Marker = (props: any) => {
  const { name } = props;
  return <div className='marker' title={name} />;
};

export default function GoogleMap(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
  };

  const contentStyle = {
    backgroundColor: ParseBgColor(props.bgColor),
    color: ParseTextColor(props.textColor),
    paddingTop: '30px',
  };

  let bootstrapKey = process.env.REACT_APP_GOOGLE_MAP_KEY
    ? { key: process.env.REACT_APP_GOOGLE_MAP_KEY }
    : null;

  console.log(props.minds[0]);
  return (
    <FullSizeSection key='google-map' style={fullSizeSectionStyle}>
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <div className='col-5' style={{ float: 'left' }}>
          <img
            src={props.minds[0].image.url}
            alt='vd'
            style={{ height: '200px', width: '200px', borderRadius: '50%' }}
          />
          <h3> {props.minds[0].name}</h3>
          <p>
            <strong>{props.minds[0].role}</strong>
            <br />
            <a href={'tel:' + props.minds[0].tel}>{props.minds[0].tel}</a>
            <br />
            <a href={'mailto:' + props.minds[0].email}>
              {props.minds[0].email}
            </a>
          </p>

          {Markdown(props.content)}
        </div>

        <div className='col-7' style={{ height: '400px' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={bootstrapKey}
              defaultCenter={{ lat: props.lat, lng: props.lng }}
              defaultZoom={props.zoom}
            >
              <Marker lat={props.lat} lng={props.lng} name={'Nevermind'} />
            </GoogleMapReact>
          </div>
        </div>
      </ContentSection>
    </FullSizeSection>
  );
}

/*bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}*/
