import { CgMenu } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function NavMobile({ data }) {
  const [open, setOpen] = useState(false);

  const animateFrom = { opacity: 0, x: -40 };
  const animateTo = { opacity: 1, x: 0 };

  const links = data.options.map((option, index) => {
    return (
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        exit={{ opacity: 0, x: -40 }}
        style={{ textAlign: 'right' }}
      >
        <Link
          key={'nav-option' + index}
          className='nav-link-mobile'
          to={option.ref}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {option.label}
        </Link>
      </motion.div>
    );
  });

  const hamburgerIcon = (
    <CgMenu className='hamburger' size='40' onClick={() => setOpen(!open)} />
  );

  const closeIcon = (
    <IoMdClose className='hamburger' size='40' onClick={() => setOpen(!open)} />
  );
  return (
    <div className='nav-mobile-wrapper'>
      <div className='nav-mobile'>
        <Link className='nav-link' to='/'>
          {data.label}
        </Link>

        {open ? closeIcon : hamburgerIcon}
      </div>

      {open && (
        <>
          <div className='nav-mobile-links'>
            <div className='link-section-mobile'>{links}</div>
          </div>
        </>
      )}
    </div>
  );
}
