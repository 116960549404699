import React from 'react';
import { FullSizeSection, ContentSection } from '../../Section';
import { IMAGE_FORMATS, IMAGE_TYPES } from '../../../utils/Constants.js';
import Markdown from '../../Markdown';
import CoverElement from '../../CoverElement';
import {
  ParseBgColor,
  ParseBgImage,
  ParseTextColor,
} from '../../../utils/parsers.js';
import { CgEnter } from 'react-icons/cg';

export default function Banner(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
    backgroundImage: ParseBgImage({
      image: props.bgImage,
      format: IMAGE_FORMATS.banner,
      type: IMAGE_TYPES.background,
    }),
    backgroundSize: 'cover',
    backgroundPosition: props.bgPosition
      ? props.bgPosition.replace('_', ' ')
      : 'center',
    height: '45vh',
    display: 'flex',
    alignItems: 'center',
  };

  const contentStyle = {
    backgroundColor: ParseBgColor(props.bgColor),
    color: ParseTextColor(props.textColor),
    display: 'flex',
    alignItems: 'center',
  };

  const bannerStyle = {
    height: '350px',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '50px',
  };

  const Banner = (props) => {
    return (
      <div className='banner' style={bannerStyle}>
        <div className='bannerText'>{Markdown(props.content)}</div>
      </div>
    );
  };

  return (
    <FullSizeSection
      className='full-size-section'
      key={'banner' + props.id}
      style={fullSizeSectionStyle}
    >
      <CoverElement coverOpacity={props.bgCoverOpacity} />
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <Banner content={props.content} />
      </ContentSection>
    </FullSizeSection>
  );
}
