import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { Switch, Route, withRouter } from 'react-router-dom';
import { PageParser } from './utils/parsers';
import { Nav } from './components/Nav';
import LoadingNeverMind from './components/LoadingNeverMind';
import NotAvailable from './components/NotAvailable';
import GA from './utils/GoogleAnalytics';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Footer from './components/Footer';

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      pages: null,
      navbar: null,
    };
  }

  componentDidMount() {
    console.log('Dev log 1');
    ReactGA.pageview(window.location.pathname);

    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/${process.env.REACT_APP_WEBPAGE_ID}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          //// TODO: validate result
          this.setState({
            isLoaded: true,
            pages: result.pages,
            navbar: result.navbar,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { isLoaded, error, pages, navbar } = this.state;
    const { location } = this.props;
    if (!isLoaded) return <LoadingNeverMind />;
    if (error) return <NotAvailable />;

    return (
      <div className='App'>
        <Nav data={navbar} />

        <AnimatePresence>
          {GA.init() && <GA.RouteTracker />}

          <Switch
            location={location}
            key={location.pathname}
            history={history}
            style={{ position: 'relative' }}
          >
            {pages.map((page, index) => (
              <Route
                key={index}
                path={page.key}
                exact={page.exact}
                children={PageParser(page)}
              />
            ))}
            <Route key={'default'} children={PageParser(pages[0])} />
          </Switch>
        </AnimatePresence>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
