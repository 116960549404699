import { IMAGE_FORMATS } from '../../../utils/Constants.js';
import { ParseBgImage } from '../../../utils/parsers.js';

export default function Card({ value }) {
  if (typeof value === 'undefined') return null;

  const cardStyle = {
    textAlign: 'left',
    margin: '20px 0px',
    paddingRight: '40px',
  };

  const imgStyle = {
    objectFit: 'cover',
    height: '200px',
    borderRadius: '50%',
    filter: 'grayscale(100%)',
  };

  return (
    <div style={cardStyle}>
      <img
        src={ParseBgImage({
          image: value.image,
          format: IMAGE_FORMATS.thumbnail,
        })}
        alt='member'
        style={imgStyle}
      />
      <br />
      <h4> {value.tagline}</h4>
      <br />
      <b>{value.name}</b>
      <br />
      {value.role}
      <br />
      <br />
      <a href={'mailto:' + value.email} style={{ textDecoration: 'none' }}>
        {value.email}
      </a>
      <br />
      <a href={'tel:' + value.tel} style={{ textDecoration: 'none' }}>
        {value.tel}
      </a>
    </div>
  );
}
