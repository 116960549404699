import Form from "./Form";
import { FullSizeSection } from "../../Section";
import { ContentSection } from "../../Section";
import Markdown from "../../Markdown";

export default function JobForm(props) {
 
  return(
    <FullSizeSection>
        <ContentSection>
            <Form thankYouText={props.thankYouText} errorText={props.ErrorText} introText={props.leadingText}/>
        </ContentSection>
    </FullSizeSection>
);
}
