import React from 'react';
import './spinner.css';

export default class LoadingNeverMind extends React.Component {
  render() {
    return (
      <div style={{ width: '100%', textAlign: 'center', marginTop: '200px' }}>
        <h1>nvrmind</h1>
        <div className='lds-roller'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
