import React from 'react';
import { FullSizeSection, ContentSection } from '../../Section';
import { IMAGE_FORMATS, IMAGE_TYPES } from '../../../utils/Constants.js';
import {
  ParseTextColor,
  ParserBgVideo,
  ParseBgImage,
} from '../../../utils/parsers.js';

import Markdown from '../../Markdown';

export default function VideoHero(props) {
  const bannerStyle = {
    height: '350px',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '50px',
  };

  const videoBG = {
    position: 'relative',
    height: '45vh',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
    backgroundImage: ParseBgImage({
      image: props.fallbackImage,
      format: IMAGE_FORMATS.banner,
      type: IMAGE_TYPES.background,
    }),
    backgroundSize: 'cover',
  };

  const contentStyle = {
    position: 'absolute',
    color: ParseTextColor(props.textColor),
    zIndex: 50,
  };

  const Banner = () => {
    return (
      <div className='banner' style={bannerStyle}>
        <div className='bannerText'>{Markdown(props.content)}</div>
      </div>
    );
  };

  return (
    <FullSizeSection className='full-size-section' style={videoBG}>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          zIndex: '2',
          backgroundImage:
            'linear-gradient(0deg, rgba(13,0,0,0) 75%, rgba(0,0,0,0.25) 100%)',
        }}
      ></div>
      <ContentSection style={contentStyle}>
        <Banner />
      </ContentSection>
      <video
        id='video'
        muted
        autoPlay
        loop
        playsInline
        poster={props.fallbackImage.url}
      >
        <source src={props.videoBG[0].url} />
      </video>
    </FullSizeSection>
  );
}
