import { FullSizeSection, ContentSection } from '../../Section';
import { IMAGE_FORMATS } from '../../../utils/Constants.js';
import {
  ParseTextColor,
  ParseBgColor,
  ParseBgImage,
  ParseLinks,
  SocialMediaLinksParser,
} from '../../../utils/parsers.js';
import Markdown from '../../Markdown';

export default function TextWithImage(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
  };

  const contentStyle = {
    color: ParseTextColor(props.textColor),
    textAlign: props.textAlign,
    backgroundColor: ParseBgColor(props.bgColor),
  };

  let direction;
  switch (props.type) {
    case 'textBottom':
      direction = 'bottom';
      break;
    case 'textTop':
      direction = 'top';
      break;
    case 'textOnly':
      direction = 'text-only';
      break;
    default:
      direction = 'right';
  }

  const srcImage = ParseBgImage({
    image: props.image,
    format: IMAGE_FORMATS.large,
  });

  return (
    <FullSizeSection
      key={'image-w-header' + props.id}
      style={fullSizeSectionStyle}
    >
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <div className={`text-image text-image-${direction} `}>
          <div>
            {Markdown(props.content)}
            {ParseLinks(props.links)}
            {SocialMediaLinksParser(props.socialMediaLinks)}
          </div>
          {props.image ? <img src={srcImage} alt='Card' /> : null}
        </div>
      </ContentSection>
    </FullSizeSection>
  );
}
