import React from 'react';

export function FullSizeSection(props) {
  const className =
    props.className !== undefined && props.className
      ? props.className
      : 'full-size-section';
  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
}

export function ContentSection(props) {
  return (
    <div
      id={props.anchor ? props.anchor : ''}
      className='content-section'
      style={props.style}
    >
      {props.children}
    </div>
  );
}
