import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

export default function Markdown(content) {
  if (!content) return null;

  return (
    <ReactMarkdown
      transformImageUri={(uri) =>
        uri.startsWith('http')
          ? uri
          : `${process.env.REACT_APP_STRAPI_URL}${uri}`
      }
    >
      {content}
    </ReactMarkdown>
  );
}
