import {IMAGE_FORMATS} from "../../../utils/Constants.js";
import {ParseBgImage} from "../../../utils/parsers.js";

export default function Card({value}) {

    const imgStyle = {
        objectFit:'cover',
        flex:1,
        borderRadius: '50%',
        height:'150px',
        width: '150px'
    }

    if (typeof(value) === "undefined") return null;


    return(
        <div className='mind-card' >
            <img src={ParseBgImage({image:value.image, format: IMAGE_FORMATS.thumbnail})} alt='member' style={imgStyle}/>
            <br/>
            <b>
                {value.name}
            </b>
            <br/>
            {value.role}
        </div>
    );
};
