import { Link } from 'react-router-dom';
import { ContentSection } from '../Section';

export default function NavLarge({ data }) {
  const links = data.options.map((option, index) => {
    return (
      <Link key={'nav-option' + index} className='nav-link' to={option.ref}>
        {option.label}
      </Link>
    );
  });
  return (
    <div className='nav'>
      <ContentSection style={{ padding: '0px' }} disableFade={true}>
        <Link className='nav-link' to='/'>
          {data.label}
        </Link>
        <div className='link-section'>{links}</div>
      </ContentSection>
    </div>
  );
}
