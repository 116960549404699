import { IMAGE_FORMATS } from '../../../utils/Constants.js';
import { ParseBgImage } from '../../../utils/parsers.js';
import Markdown from '../../Markdown';

export default function Card({ value }) {
  if (typeof value === 'undefined') return null;

  return (
    <div
      // className='col col-6 vmargin'
      className='crowdCard'
    >
      <img
        height='230px'
        width='230px'
        src={ParseBgImage({ image: value.logo, format: IMAGE_FORMATS.medium })}
        alt='Crowd'
        style={{
          objectFit: 'cover',
          borderRadius: '50%',
          marginBottom: '10px',
        }}
      />
      <h4 style={{ marginTop: '1%', marginBottom: '0px' }}>{value.label}</h4>
      {Markdown(value.desc)}
    </div>
  );
}
