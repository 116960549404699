import NavLarge from './NavLarge';
import NavMobile from './NavMobile';

export function Nav({ data }) {
  return (
    <>
      <NavLarge data={data} />
      <NavMobile data={data} />
    </>
  );
}
