import { FullSizeSection, ContentSection } from '../../Section';
import { ParseBgColor, ParseTextColor } from '../../../utils/parsers.js';
import Card from './Card';
import Markdown from '../../Markdown';

export default function CrowdView(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
  };

  const contentStyle = {
    backgroundColor: ParseBgColor(props.bgColor),
    color: ParseTextColor(props.textColor),
  };

  let listItems = null;
  if (Array.isArray(props.crowds) && props.crowds.length) {
    listItems = props.crowds.map((crowd, i) => (
      <Card key={'crowd' + i.toString()} value={crowd} />
    ));
  }

  return (
    <FullSizeSection key={'crowd-card' + props.id} style={fullSizeSectionStyle}>
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <div className='col-8'>{Markdown(props.content)}</div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {listItems}
        </div>
      </ContentSection>
    </FullSizeSection>
  );
}
