import React, { useState } from "react";


export default function Input(props) {
  const [state, setValue] = useState('form-label');

  function doesInputHasValue(e) {
     const hasVal = e.target.value.length > 0 ? 'form-label hasVal' : 'form-label noVal'
     setValue(hasVal)
 }

 function shouldElementBeVisible(props) {
   const visibility = props.type !== 'hidden' ? {display: 'block'} : {display: 'none'}
   return visibility
 }

 function renderLabel(props) {
   const label = <label htmlFor={props.for}>{props.text}</label>
   if(props.type !== 'hidden') {
      return label
      } else {
      return false
    }
 }

  return(
    <div className={state} style={shouldElementBeVisible(props)}>
        {renderLabel(props)}
        <input required type={props.type} id={props.for} name={props.for} onChange={doesInputHasValue} onFocus={doesInputHasValue}></input>
        <span className="required-field-text">*Obligatoriskt fält</span>
    </div>
);
}