import { FullSizeSection, ContentSection } from '../../Section';
import { ParseBgColor, ParseTextColor } from '../../../utils/parsers.js';
import Card from './Card';
import Markdown from '../../Markdown';
import styled from 'styled-components';

export default function JobView(props) {
  const fullSizeSectionStyle = {
    backgroundColor: ParseBgColor(props.outerBgColor),
  };

  const contentStyle = {
    backgroundColor: ParseBgColor(props.bgColor),
    color: ParseTextColor(props.textColor),
  };

  let listItems = null;
  if (Array.isArray(props.jobs) && props.jobs.length) {
    listItems = props.jobs.map((job, i) => (
      <Card
        key={'job' + i.toString()}
        // tag={'job' + i.toString()}
        value={job}
        color={props.cardTextColor}
      />
    ));
  }

  return (
    <FullSizeSection key={'job-card' + props.id} style={fullSizeSectionStyle}>
      <ContentSection style={contentStyle} anchor={props.anchor}>
        <div className='col-8'>
          <MarkdownStyles>{Markdown(props.content)}</MarkdownStyles>
        </div>
        <div className='card-container'>{listItems}</div>
      </ContentSection>
    </FullSizeSection>
  );
}

const MarkdownStyles = styled.div`
  a {
    color: #f1635f;
    text-decoration: none;
  }
  a:hover {
    color: #71acda;
  }
`;
