import { HashLink as Link } from 'react-router-hash-link';
import './link.css';

export default function LinkElem(props) {

    if (typeof(props) === "undefined") return null;
    if (props.label === "undefined" || !props.label) return null;

    let className = 'link';
    if(props.className && props.className !== 'none'){
        className = props.className;
    }

    const key = (props.key !== undefined)?props.key:'link';

    return(
        <Link key={key} className={className} to={props.ref}>
            {props.label}
            {props.rowBreak ? <br /> : null }
        </Link>
    );
};
